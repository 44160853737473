<template>
  <div>
    <div class="frmBlock__layout">
      <span
        class="frmBlock__unit"
        v-if="option.componentOption.properties[0].elements.unit"
        >{{ option.componentOption.properties[0].elements.unit }}</span
      >
      <input
        type="number"
        class="frmBlock__textField"
        :value="number"
        min="0"
        @input="updateNumber($event.target.value)"
      />
      <div
        class="frmBlock__counter"
        :class="{
          'frmBlock__counter--option': $route.path === '/option'
        }"
      >
        <button
          type="button"
          class="frmBlock__counter--up"
          @click="increaseNumber($event)"
        >
          <span>증가</span>
        </button>
        <button
          type="button"
          class="frmBlock__counter--down"
          @click="decreaseNumber($event)"
        >
          <span>감소</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      number: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties[0].initialValue
        : this.option.value.number;
    }
  },
  mounted() {
    this.number = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.initialValue);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateNumber(value) {
      this.number = value;
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        value
      );
      if (this.$route.path === "/option") {
        this.updateProperty(value);
      }
    },
    // number increase
    increaseNumber(e) {
      e.currentTarget.parentNode.previousSibling.focus();
      let value = Number(this.number) + 1;
      this.updateNumber(value);
    },
    // number decrease
    decreaseNumber(e) {
      e.currentTarget.parentNode.previousSibling.focus();
      if (Number(this.number) > 0) {
        let value = Number(this.number) - 1;
        this.updateNumber(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.frmBlock__counter {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 24px;
}
.frmBlock__counter button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 20px;
}
.frmBlock__counter--up {
  border-radius: 4px 4px 0px 0px;
}
.frmBlock__counter--down {
  border-radius: 0px 0px 4px 4px;
}
.frmBlock__counter--up:after,
.frmBlock__counter--down:after {
  color: #797979;
  transform: scale(0.5, 0.4);
}
.frmBlock__unit {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 34px;
  color: #8d8d8d;
  font-size: 14px;
}
.frmBlock__textField[type="number"]:enabled:focus + .frmBlock__counter button,
.frmBlock__textField[type="number"]:enabled:hover + .frmBlock__counter button {
  background-color: #d2f8ec;
  background-repeat: no-repeat;
  background-size: 8px auto;
}
.frmBlock__textField[type="number"]:enabled:focus
  + .frmBlock__counter
  button:after,
.frmBlock__textField[type="number"]:enabled:hover
  + .frmBlock__counter
  button:after {
  color: #54c7a2;
}
.frmBlock__textField[type="number"]:disabled + .frmBlock__counter button:after,
.frmBlock__textField[type="number"]:disabled + .frmBlock__counter button:after {
  color: #dcdcdc;
}
.frmBlock__counter--up:enabled:hover:after,
.frmBlock__counter--down:enabled:hover:after {
  color: #309574 !important;
}
.frmBlock__counter span {
  text-indent: -9999px;
}
.frmBlock__counter--up:after {
  content: "\25B2";
  margin-top: 3px;
}
.frmBlock__counter--down:after {
  content: "\25BC";
  margin-top: -3px;
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .frmBlock__counter {
    display: none;
    &--option {
      display: block;
    }
  }
}
</style>
