var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_vm.option.componentOption.properties[0].elements.unit ? _c('span', {
    staticClass: "frmBlock__unit"
  }, [_vm._v(_vm._s(_vm.option.componentOption.properties[0].elements.unit))]) : _vm._e(), _c('input', {
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.number
    },
    on: {
      "input": function input($event) {
        return _vm.updateNumber($event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "frmBlock__counter",
    class: {
      'frmBlock__counter--option': _vm.$route.path === '/option'
    }
  }, [_c('button', {
    staticClass: "frmBlock__counter--up",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.increaseNumber($event);
      }
    }
  }, [_c('span', [_vm._v("증가")])]), _c('button', {
    staticClass: "frmBlock__counter--down",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.decreaseNumber($event);
      }
    }
  }, [_c('span', [_vm._v("감소")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }